.credits-view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem 0 2rem;
  padding-bottom: 5.2rem;
  overflow-y: hidden;
  white-space: pre-line;
  text-align: center;
  pointer-events: none;
  z-index: 1024;
}

.credits-view__inner {
  position: absolute;
  padding: 100vh 0;
  top: 0;
  left: 0;
  right: 0;
  max-width: 60rem;
  margin: auto;
  transform: translate3d(0, 0, 0);
  transition: transform 60s linear;
}

.credits-view__backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba($black, 0.4);
  opacity: 0;
}

.credits-view__title {
  user-select: none;
  svg {
    width: 360px;
    @include media-breakpoint-down("xs") {
      width: 260px;
    }
  }
}

.credits-view__credit {
  text-align: center;
  font-size: 0;
}

.credits-view__credit:not(:last-child) {
  margin-bottom: 4rem;
}

.credits-view__name {
  display: block;
  @include typo--audio-subtitle;
}

.credits-view__role {
  display: block;
  @include typo--audio-subtitle-name;
}
