@mixin typo--title {
  font-family: $font-family-sans-serif;
  font-size: 4.5rem;
  line-height: 1.02em;
  font-weight: 500;

  @include media-breakpoint-down("md") {
    font-size: 2rem;
  }
}

@mixin typo--subtitle {
  font-family: $font-family-sans-serif;
  font-size: 24px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.04em;
}

@mixin typo--body {
  font-family: $font-family-text;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: 0.02em;
}

@mixin typo--smallprint {
  font-family: $font-family-sans-serif;
  font-size: 1.2rem;
  line-height: 1.5em;
  letter-spacing: 0.15em;
  font-weight: normal;
}

@mixin typo--credits {
  font-family: $font-family-sans-serif;
  font-size: 2.6rem;
  line-height: 1.166em;
  font-weight: 500;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  @include media-breakpoint-down("md") {
    font-size: 1.75rem;
  }
}

@mixin typo--credits-role {
  font-family: $font-family-sans-serif;
  font-size: 1.25rem;
  line-height: 1.166em;
  font-weight: normal;
  letter-spacing: 0.04em;

  @include media-breakpoint-down("md") {
    font-size: 0.9rem;
  }
}

@mixin typo--button {
  font-family: $font-family-sans-serif;
  font-size: 1rem;
  line-height: 1em;
  font-weight: normal;
  letter-spacing: 0.01em;
}

@mixin typo--metadata {
  font-family: $font-family-monospace;
  font-size: 1rem;
  line-height: 1em;
  font-weight: 500;
  letter-spacing: 0.01em;
}

@mixin typo--camera-preset {
  font-family: $font-family-monospace;
  font-size: 1rem;
  line-height: 1.25em;
  font-weight: 500;
  letter-spacing: 0.01em;
}

@mixin typo--clock {
  font-family: $font-family-monospace;
  font-size: 1rem;
  line-height: 1em;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-variant-numeric: tabular-nums;
  font-feature-settings: "tnum";
}

@mixin typo--audio-subtitle {
  font-family: $font-family-sans-serif;
  font-size: 26px;
  line-height: 1.083em;
  font-weight: 500;
  letter-spacing: 0.04em;
  color: #8ed498;
  -webkit-text-stroke: 1px black;

  @include media-breakpoint-down("md") {
    font-size: 20px;
  }
}

@mixin typo--audio-subtitle-name {
  font-family: $font-family-sans-serif;
  font-size: 26px;
  line-height: 1.083em;
  font-weight: 500;
  letter-spacing: 0.04em;
  color: #fff;
  -webkit-text-stroke: 1px black;

  @include media-breakpoint-down("md") {
    font-size: 20px;
  }
}

.typo--title {
  @include typo--title;
}

.typo--subtitle {
  @include typo--subtitle;
}

.typo--body {
  @include typo--body;
}

.typo--smallprint {
  @include typo--smallprint;
}

.typo--credits {
  @include typo--credits;
}

.typo--credits-role {
  @include typo--credits-role;
}

.typo--button {
  @include typo--button;
}

.typo--metadata {
  @include typo--metadata;
}

.typo--camera-preset {
  @include typo--camera-preset;
}

.typo--clock {
  @include typo--clock;
}

.typo--audio-subtitle {
  @include typo--audio-subtitle;
}

.typo--audio-subtitle-name {
  @include typo--audio-subtitle-name;
}
