.hud-view__content {
  position: fixed;
  width: 100%;
  height: 3rem;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
}

.hud-view {
  font-size: 0;
  user-select: none;

  &__content-section {
    transition: opacity 0.3s ease;

    &--start {
      flex-basis: 33.33333%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &--center {
      flex-basis: 33.33333%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--end {
      flex-basis: 33.33333%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &--start,
    &--end {
      > *:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }
}

.hud-view__content-section--center {
  @include media-breakpoint-down("md") {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 3rem;
    justify-content: center;
    padding: 0 0.5rem;
  }
}

.mr-icons > svg:not(:last-child) {
  margin-right: 1.5rem;
}

.viewfinder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  max-width: calc(100vw - 20px);
  height: auto;
}

.mr-icon {
  will-change: color;
  transition: color 0.2s ease;
}

.mr-icon[data-mode="on"] {
  color: $success;
}

.mr-icon[data-mode="flash"] {
  transition: none;
  animation: flash-color 2s infinite;
}

.camera-preset {
  color: $grey;
  margin-right: 1.5rem;
}

.camera-flash {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $white;
  pointer-events: none;
  opacity: 0;
  will-change: opacity;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.scroll-hint {
  position: fixed;
  bottom: 3.5rem;
  left: calc(50% - 95px);
  margin: auto;
  will-change: transform;
  transition: transform 0.4s ease-in;
  transform: translate(0, 10rem);
  pointer-events: none;
  animation: float 3s ease-in-out infinite;
  opacity: 0;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}

.scroll-hint::before {
  content: "scroll & explore";
  @media (hover: none) {
    content: "swipe & explore";
  }
}

.scroll-hint--is-active {
  transform: translate(0, -2rem);
  transition: transform 0.4s ease-out;
  opacity: 1;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}

.hud-view__buffering {
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 768px) {
    top: 3.2rem;
  }
}
