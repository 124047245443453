// Core
@import "variables";
@import "functions";
@import "mixins";
@import "utilities";

// Reboot
@import "reboot";

// CSS-Vars
@import "root";

// Helpers
@import "helpers";

// Utilities
@import "utilities/api";

// Typography
@import "type";

// Animations
@import "animations";

// Elements
@import "elements";

// Components
@import "components";

body,
html {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.desktop-only {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.mobile-only {
  @media (min-width: 769px) {
    display: none !important;
  }
}
