@keyframes flash-color {
  50% {
    color: $success;
  }
}

@keyframes flash-opacity {
  50% {
    opacity: 0;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.animation-flash-opacity {
  animation-name: flash-opacity;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}
