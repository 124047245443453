$btn-height: 2rem !default;
$btn-padding: 8px !default;

$btn-font-family: $font-family-monospace !default;
$btn-font-size: 1rem !default;
$btn-font-weight: 500 !default;
$btn-line-height: 1.4 !default;
$btn-letter-spacing: 0.05em !default;
$btn-text-transform: uppercase !default;

$btn-border-width: 2px !default;
$btn-border-radius: 8px;
$btn-border-color: $black !default;
$btn-background-color: transparent !default;
$btn-color: $black !default;

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: $btn-height;
  padding: 2px $btn-padding 0 $btn-padding;
  font-family: $btn-font-family;
  font-size: $btn-font-size;
  font-weight: $btn-font-weight;
  line-height: $btn-line-height;
  letter-spacing: $btn-letter-spacing;
  text-transform: $btn-text-transform;
  text-align: center;
  border: $btn-border-width solid $btn-border-color;
  border-radius: $btn-border-radius;
  background-color: $btn-background-color;
  color: $btn-color;
  appearance: none;
  user-select: none;
  outline: 0;
  white-space: nowrap;
  transition: opacity 0.15s ease;

  &.is-icon,
  &.has-icon {
    .icon {
      font-size: 1.25rem;
    }
  }

  &.is-icon {
    padding: 0 $btn-padding * 0.75 - $btn-border-width;
  }

  &.has-icon {
    padding-left: $btn-padding * 0.75;

    .icon {
      margin-top: -2px;
      margin-right: $btn-padding * 0.75;
    }
  }

  // Size Variants
  &.is-large {
    $btn-height: 3rem;
    $btn-padding: 18px;
    $btn-font-size: 1.25rem;

    height: $btn-height;
    padding: 3px $btn-padding 0 $btn-padding;

    &.is-icon,
    &.has-icon {
      .icon {
        font-size: 1.875rem;
      }
    }

    &.is-icon {
      padding: 0 $btn-padding * 0.5 - $btn-border-width;
    }

    &.has-icon {
      padding-left: $btn-padding * 0.5;
      .icon {
        margin-top: -2px;
        margin-right: $btn-padding * 0.5;
      }
    }
  }

  &.is-xlarge {
    $btn-height: 4rem;
    $btn-padding: 24px;
    $btn-font-size: 2rem;

    height: $btn-height;
    padding: 3px $btn-padding 0 $btn-padding;

    &.is-icon,
    &.has-icon {
      .icon {
        font-size: 1.875rem;
      }
    }

    &.is-icon {
      padding: 0 $btn-padding * 0.5 - $btn-border-width;
    }

    &.has-icon {
      padding-left: $btn-padding * 0.5;
      .icon {
        margin-top: -2px;
        margin-right: $btn-padding * 0.5;
      }
    }
  }

  &.is-text {
    padding: 0 !important;
    height: auto;
    border-color: 0;
    background: none;
    color: $grey;
  }

  // Color Variants

  &.is-transparent {
    border-color: transparent;
    color: $grey;
  }

  &.is-white {
    border-color: $white;
    color: $white;

    &:active {
      border-color: $black;
      color: $black;
    }

    @media (hover: hover) {
      &:hover {
        border-color: $black;
        color: $black;
      }
    }

    &.is-filled {
      background-color: $white;
      color: $black;

      &:active {
        border-color: $black;
        background-color: $black;
        color: $white;
      }

      &:disabled {
        color: lighten($black, 50%);
      }

      @media (hover: hover) {
        &:hover {
          border-color: $black;
          background-color: $black;
          color: $white;
        }
      }
    }
  }

  &.is-black {
    border-color: $black;
    color: $black;

    &.is-filled {
      background-color: $black;
      color: $white;

      @media (hover: hover) {
        &:hover {
          border-color: lighten($black, 10%);
          background-color: lighten($black, 10%);
        }
      }

      &:disabled {
        opacity: 0.5;
      }
    }
  }

  &.is-grey {
    border-color: $grey;
    color: $grey;

    &:active {
      color: lighten($grey, 10%);
      border-color: lighten($grey, 10%);
    }

    @media (hover: hover) {
      &:hover {
        color: lighten($grey, 10%);
        border-color: lighten($grey, 10%);
      }
    }

    &.is-filled {
      background-color: $grey;
      color: $black;

      &:active {
        background-color: lighten($grey, 10%);
      }

      @media (hover: hover) {
        &:hover {
          background-color: lighten($grey, 10%);
        }
      }
    }
  }

  &.is-info {
    border-color: $info;
    color: $info;

    &.is-filled {
      background-color: $info;
      color: $black;
    }
  }

  &.is-success {
    border-color: $success;
    color: $success;

    &:active {
      color: lighten($success, 10%);
      border-color: lighten($success, 10%);
    }
    @media (hover: hover) {
      &:hover {
        color: lighten($success, 10%);
        border-color: lighten($success, 10%);
      }
    }

    &.is-filled {
      background-color: $success;
      color: $black;

      &:active {
        background-color: lighten($success, 10%);
      }

      @media (hover: hover) {
        &:hover {
          background-color: lighten($success, 10%);
        }
      }
    }
  }

  &.is-warning {
    border-color: $warning;
    color: $warning;

    &.is-filled {
      background-color: $warning;
      color: $black;
    }
  }

  &.is-danger {
    border-color: $danger;
    color: $danger;

    &.is-filled {
      background-color: $danger;
      color: $black;
    }
  }

  &.is-non-interactive {
    pointer-events: none;
  }

  &.is-hidden {
    opacity: 0;
    pointer-events: none;
  }

  &.rounded-corners {
    border-radius: 1111px;
  }
}

button.button {
  cursor: pointer;
}
