.clock {
  @include typo--clock;
}

.clock__next-show {
  color: $grey;
}

.clock__countdown {
  color: $success;
}

.clock__countdown-colon {
  animation: blink 1s step-start infinite;
}
